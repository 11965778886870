<template>
  <ServiceRadioField :choices="serviceSelections" v-on="$listeners" />
</template>

<script>
import {
  insulationBottomNL,
  insulationCavityWallNL,
  insulationCrawlspaceNL,
  insulationFloorNL,
  insulationRoofNL,
} from 'chimera/insulation/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceSelections: [
        new Selectable(
          'Kruipruimteisolatie',
          'Kruipruimteisolatie',
          insulationCrawlspaceNL.id,
        ),
        new Selectable('Dakisolatie', 'Dakisolatie', insulationRoofNL.id),
        new Selectable('Vloerisolatie', 'Vloerisolatie', insulationFloorNL.id),
        new Selectable(
          'Spouwmuurisolatie',
          'Spouwmuurisolatie',
          insulationCavityWallNL.id,
        ),
        new Selectable('Bodemisolatie', 'Bodemisolatie', insulationBottomNL.id),
      ],
    }
  },
}
</script>
